import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { ListBoxType } from "../types/listBox";
import { useState } from "react";
import Icon from "./Icon";
import ListRepo from "./ListRepo";

function ListBox({ user, isLoading }: ListBoxType) {
  const [open, setOpen] = useState(0);

  const handleOpen = (value: number) => {
    setOpen(open === value ? 0 : value);
  };

  const customAnimation = {
    mount: { scale: 1 },
    unmount: { scale: 0.9 },
  };

  if (isLoading) return <></>;
  return (
    <>
      {user && user.length > 0
        ? user.map((data) => {
            return (
              <div key={data.id} className="mt-4">
                <Accordion
                  open={open === data.id}
                  animate={customAnimation}
                  className="border pl-4 pr-4 border-gray-400 rounded-md bg-gray-100"
                  icon={<Icon id={1} open={open} />}
                >
                  <AccordionHeader
                    className="font-bold"
                    onClick={() => handleOpen(data.id)}
                  >
                    {data.login}
                  </AccordionHeader>
                  <AccordionBody>
                    <div className="flex flex-col">
                      {data.repository && data.repository.length > 0
                        ? data.repository.map((repo) => (
                            <ListRepo key={repo.id} repo={repo} />
                          ))
                        : null}
                    </div>
                  </AccordionBody>
                </Accordion>
              </div>
            );
          })
        : null}
    </>
  );
}

export default ListBox;
