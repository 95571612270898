import { FaStar } from "react-icons/fa";
import { RepositoryType } from "../types/getRepository";

function ListRepo({ repo }: { repo: RepositoryType }) {
  return (
    <div className="border border-gray-600 p-4 mt-2 rounded-md w-full flex flex-row items-center">
      <div className="w-1/2 justify-start">
        <div className="text-lg">{repo.name}</div>
        <div className="text-xs">{repo.description}</div>
      </div>

      <div className="flex w-full justify-end flex-row items-center gap-2">
        {repo.stargazers_count}
        <FaStar className="text-orange-600" />
      </div>
    </div>
  );
}

export default ListRepo;
