import { useState } from "react";
import axios from "axios";
import SearchBox from "../components/SearchBox";
import LoadingSpinnerWithTextComponent from "../components/LoadingSpinnerWithText";
import { GetUserType, UserType } from "../types/getUser";
import { RepositoryType } from "../types/getRepository";
import ListBox from "../components/ListBox";

function Home() {
  const [search, setSearch] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [listUser, setListUser] = useState<UserType[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleSubmit = async () => {
    if (!search) return alert("Please enter username!");
    setIsLoading(true);
    setErrorMessage("");
    const { data, status } = await axios.get<GetUserType>(
      `https://api.github.com/search/users?q=${search}%20type:user%20repos:%3E1+followers:%3E1&page=1&per_page=5`
    );

    if (status !== 200) return alert("Failed to fetch data.");
    if (data.items && data.items.length > 0) {
      let listUser: UserType[] = [...data.items];
      for (let i = 0; i < data.items.length; i++) {
        const { data: repository } = await axios.get<RepositoryType[]>(
          data.items[i].repos_url
        );
        if (repository.length > 0) {
          listUser[i].repository = repository;
        }
      }
      setListUser(listUser);
    } else {
      setListUser([]);
      setErrorMessage("SORRY, DATA NOT FOUND.");
    }

    setIsLoading(false);
  };

  return (
    <div className="flex mt-10 flex-col w-full justify-center items-center">
      <SearchBox
        placeholder="Enter username."
        setSearch={setSearch}
        name="searchName"
      />
      <div className="w-[85%] lg:w-[50%] mt-4">
        <button
          type="submit"
          className={`fp-btn ${isLoading && "loading"}`}
          disabled={isLoading}
          onClick={handleSubmit}
        >
          {isLoading ? (
            <LoadingSpinnerWithTextComponent label={"Loading..."} />
          ) : (
            "Search"
          )}
        </button>
      </div>
      <div className="w-[85%] lg:w-[50%]">
        <ListBox user={listUser} isLoading={isLoading} />
        {errorMessage ? (
          <div className="mt-4 text-center">
            <hr />
            <div className="font-bold text-red-800 mt-4">{errorMessage}</div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Home;
